.SP {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.year-section {
  margin-bottom: 40px;
}

.year-title {
  font-size: 20px;
  font-weight: bold;
  align-content:center;
  margin-bottom: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
}
