.contact-card {
    width: 100%;
    max-width: 25vw;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .contact-card-place {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  
  /* ContactUs.css */
  
  .contact-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    width: 100%;
    gap: 20px;
    box-sizing: border-box;
  }
  
  .map-container {
    flex: 1; 
    margin-left: 10px;
    margin-right: 10px;
    height: 40vh;
    max-width: 100%;
  }
  
  .contact-details {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  /* Styles for contact details elements */
  .contact-details h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .contact-details p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .additional-contact-details {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .additional-details{
    height: 20vh;
    width: 100%;
    max-width: 33.333%;
    margin: 10px;
  }
  .additional-contact-details {
    display: flex;
    flex-wrap: wrap;
  }
  
  /* .fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  } */
  
  /* .visible {
    opacity: 1;
    transform: translateY(0);
  } */
  
  .college-section,
  .additional-contact-section {
    margin-bottom: 20px;
  }
  
  .contact-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: space-between;
  }
  
  .member {
    border: 1px solid #ddd;
    cursor: pointer;
    height: 40vh;
    padding: 20px;
    width: 100%;
    max-width: 33.333%;
    margin: 10px;
  }
  /* 
  .member:hover {
    transform: scale(1.05);
  } */
  
  .member h3 {
    margin-bottom: 12px;
  }
  
  .paper-details{
    height: 20vh;
    width: max-content;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin: 10px;
  }
  
  
  .cd{
    color: rgb(20, 6, 63);
    text-align: center; 
  }

  .contact-details p:last-child {
  margin-bottom: 0; /* Remove margin from the last paragraph element */
}