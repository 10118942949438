/* Dropdown.css */
.topnav {
  background-image: linear-gradient(to right, 	#555555, #A9A9A9);
  text-align: center;
  font-size: 20px;
  height: 50px;
  /* width: 90vw; */
  margin-left: auto;
  margin-right: auto;
}
  
 
button {
  border-style: none;
  /* background-color: #690001; */
}



.digitali-icon {
  color: "white"
  
}
  
.topnav a {
  display: block;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  padding-left: 10px;
}
  
.a:hover {
  color: #ffffff;
}
  
.active {
  /* background-color: #690001; */
  color: white;
}
  
.result-icon {
  height: 30px;
}
  
.dropbtn {
  /* background-color: #690001; */
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  text-align: center;
  display: flex; /* Make the button a flex container */
  align-items: center;
}
  
.dropbtn:hover {
  background-color: #555555;
  color: #ffffff;
}
  
.dropdown {
  position: relative;
  display: inline-block;
  /* background-color: #035a5a; */
}

.drop-icon {
  height: 30px;
  margin-right: 5px;
}
  
 .dropdown-content {
  display: none;
  position: absolute;
  background-color: #555555;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 3;
  min-width: 200px;
  width: 500px;
  overflow: hidden;
} 


  
.dropdown-content:hover {
  color: #555555;
}
 
.dropdown-content button {
  color: rgb(165, 221, 141);
  text-decoration: none;
  display: block;
  padding: 10px;
  text-align: center;
  height: auto;
  
}
  
.dropbtn:hover .dropdown-content {
  background-color: #101010;
}
  
.dropdown-content button {

  background-color: #555555;
  width: 100%;
}
  
.dropdown-content:hover button:hover {
  background-color: #5e6868;
  color: rgb(255, 255, 255);
}
  
.link-btn {
  text-align: left;
  color: #ffffff;
  width: 100%;
  font-weight: 500;
}
  
.link-btn:hover {
  color: #ffffff;
}

button:hover {
  color: #ffffff;
}
  
.dropdown:hover .dropdown-content {
  display: block;
  width: 200px;
}

.drop-icon {
  height: 30px;
}

.menu-icon {
  display: none;
}
  
  
@media screen and (max-width: 600px) {
  .menu-icon {
    display: flex;
    color: #ffffff;
    justify-content: flex-start;
  }

  .menu-off-options {
    display: none;
  }

  .menu-on-options {
    display: flex;
    flex-direction: column;
  }

  .topnav {
    height: auto;
  }
}
