.notifications-container {
    width: 100%;
    height: 100%; /* Ensure the container fills its parent */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .notifications-box {
    width: 800px; /* Adjust width as needed */
    height: 300px; /* Adjust height as needed */
    overflow: hidden;
    border: 1px solid black; /* Add a black border */
    margin: auto; /* Center the box horizontally */
  }
  
  .notification-scroll {
    animation: scroll-up 360s linear infinite; /* Adjust animation duration */
  }
  
  .notification {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Animation to continuously scroll notifications */
  @keyframes scroll-up {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-50%);
    }
  }