/* Enhanced responsive table styling for better UI and UX with changed background colors */
@media (min-width: 768px) {
  table {
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  th, td {
    border: 1px solid #0d0d0d;
    padding: 15px;
    text-align: left;
    font-size: 16px;
  }
  
  th {
    background-color: #333333; /* Changed background color to a darker shade */
    color: blue;
    font-weight: bold;
  }
}

@media (max-width: 767px) {
  /* For mobile screens, display table data in a non-tabulated format with enhanced styling and changed background colors */
  table, th, td {
    display: block;
    margin-bottom: 10px;
  }
  
  td {
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    background-color: #f0f0f0; /* Changed background color to a lighter shade */
  }
  
  th {
    background-color: #666666; /* Changed background color to a darker shade */
    padding: 15px 0;
    text-align: left;
    color: #fff;
    font-weight: bold;
  }
}

.SP {
  color: #2e0669;
  text-align: center;
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: bold;
}

label {
  margin-right: 10px;
  font-size: 16px;
}

.search-container {
  display: flex;
  justify-content: right;
  margin-bottom: 15px;
  align-items: center;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  align-items: center;
}

.pagination-container button {
  margin-left: 10px;
  background-color: #035a5a;
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 20px;
}

.pagination-container button:disabled {
  background-color: #bdc3c7;
  color: #181818;
  cursor: not-allowed;
}